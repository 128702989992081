import React, { useState, useEffect, useRef } from 'react';
import * as _ from "lodash";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import 'moment-timezone';
import { Form, Input, Button, DatePicker, Table, Spin, Pagination, Select } from 'antd';

import API, { BOOKMAKER, USER } from '../../network/api';
import { downloadExcel } from 'react-export-table-to-excel';
import ModalCommon from '../../components/Modal';
import { TEXT_DEF, GET_DATA_FAIL } from "../../constant/text";
import { hasRight } from "../../components/until/right";
import { RIGHTS } from "../../constant/rights";
import dayjs from 'dayjs'

import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';

const AccountAdminList = () => {
  let isRequest = useRef(false);
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const navigate = useNavigate();
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [adminList, setAdminList] = useState([]);
  const indexTabLocal = localStorage.getItem('indexTabAcc') || 1;
  const [indexTab, setIndexTab] = useState(Number(indexTabLocal));
  localStorage.removeItem('indexTabAcc');
  const [pageAdmin, setPageAdmin] = useState(1);
  const [totalRecordAdmin, setTotalRecordAdmin] = useState(0);
  const locale = {
    emptyText: TEXT_DEF.text0043,
  };
  const [loading, setLoading] = useState(false);
  const [idField, setIdField] = useState();
  const [dataBlock, setDataBlock] = useState();
  const [txtMsg, setTxtMsg] = useState();
  const [isShowModalConfirm, setIsShowModalConfirm] = useState(false);
  const [isShowModalSuccess, setIsShowModalSuccess] = useState(false);
  const [bookmakerSl, setBookmakerSl] = useState(0);
  const [page, setPage] = useState(1);
  const [params, setParams] = useState('');
  const [bookmarkers, setBookmarkers] = useState([]);
  const [status, setStatus] = useState('');

  const gameStatus = [
    {
      key: 1,
      value: false,
      label: 'Active',
    }, {
      key: 2,
      value: true,
      label: 'Deactive',
    },
  ];

  useEffect(() => {
    forceUpdate({});
    getBookmarkers();
  }, []);

  // useEffect(() => {
  //   form.setFieldValue('dateTo', dayjs())
  //   form.setFieldValue('dateFrom', dayjs().add(-3, 'month'))
  //   setDateFrom(dayjs().add(-3, 'month'))
  //   setDateTo(dayjs())
  // }, [])

  useEffect(() => {
    getAdminUsers();
  }, [params]);
  const disabledDateFrom = (current) => {
    if (!dateTo) {
      return current && current > dayjs().endOf('day');
    }
    return current && (current > dayjs().endOf('day') || current < dayjs(dateTo).subtract(3, 'months').startOf('day') || current > dayjs(dateTo).endOf('day'));
  };

  const disabledDateTo = (current) => {
    if (!dateFrom) {
      return current && current > dayjs().endOf('day');
    }
    return current && (current > dayjs().endOf('day') || current < dayjs(dateFrom).startOf('day') || current > dayjs(dateFrom).add(3, 'months').endOf('day'));
  };

  const getBookmarkers = async () => {
    const result = await API.get(`${BOOKMAKER}/all`);
    const bookmarkers = result.data?.data?.result[0] || [];
    if (!bookmarkers || bookmarkers.length === 0) return;

    const tempBookmarkers = bookmarkers?.map((item) => {
      return {
        key: item?.id,
        value: item?.id,
        label: item?.name,
      };
    });
    setBookmarkers(tempBookmarkers);
  };

  const getAdminUsers = async () => {
    try {
      const res = await API.get(`${USER}?${params}`);
      const users = _.get(res, 'data.data.users', []);
      const finalResult = [];
      for (const item of users) {
        finalResult.push({
          key: item?.id,
          id: item?.id,
          username: item?.username,
          bookmaker: item?.bookmaker?.name,
          createdAt: item?.createdAt,
          updatedAt: item?.updatedAt,
          updatedBy: item?.updatedBy,
          isBlocked: item?.isBlocked,
          role: item?.roleAdminUser?.name,
        });
      }
      setAdminList(finalResult);
      setTotalRecordAdmin(res?.data.data.total);
    } catch (err) {
      setLoading(false);
      toast(GET_DATA_FAIL);
    }
  };

  const onChangePage = (page) => {
    getParams('change_page', { page });
  };

  const showTotal = (total) => `Tổng cộng ${total} tài khoản`;

  const search = () => {
    getParams('search');
  }

  const getParams = (action = '', data = {}) => {
    let params = `skip=${page}`;
    const bookmarker = form.getFieldValue('bookmarker');
    const username = form.getFieldValue('username');
    const dateFrom = form.getFieldValue('dateFrom');
    const dateTo = form.getFieldValue('dateTo');
    const status = form.getFieldValue('status');

    if (action === 'search') {
      setPage(1);
      params = `skip=1`;
    } else if (action === 'change_page') {
      params = `skip=${data?.page || 1}`;
      setPage(data?.page || 1);
    }

    if (bookmarker) {
      params += `&bookmarkerId=${bookmarker}`;
    }
    if (username) {
      params += `&username=${username}`;
    }
    if (action !== 'change_page') {
      if (dateFrom) {
        params += `&fromDate=${dateFrom}`;
      }
      if (dateTo) {
        params += `&toDate=${dateTo}`;
      }
    }

    if (status === true || status === false) {
      params += `&status=${status}`;
    }
    setParams(params);
  };

  const handleShowModal = (id, isBlocked) => {

    setIsShowModalConfirm(true);
    setIdField(id);
    setDataBlock(!isBlocked)

    setTxtMsg({
      confirm: `Bạn chắc chắn muốn ${!isBlocked ? 'Khóa' : 'Mở khóa'} tài khoản này ?`,
      success: `Bạn đã ${!isBlocked ? 'Khóa' : 'Mở khóa'} thành công !`,
    });
  };

  const handleBlockAccount = async () => {
    if (isRequest.current) {
      return;
    };

    isRequest.current = true;
    try {
      setIsShowModalConfirm(false);

      const res = await API.patch(`${USER}/admin/${idField}/block`, { isBlocked: dataBlock });
      if (res?.data?.data?.code === 20004) {

        setDataBlock(undefined);
        setIdField(undefined);

        await getAdminUsers();
      }
      isRequest.current = false;
    } catch (err) {
      console.log(err);
      isRequest.current = false;
    } finally {
      setIsShowModalSuccess(true);
      isRequest.current = false;
    }
  };

  const colums = [
    {
      title: 'STT',
      align: 'center',
      key: 'order',
      render: (text, record, index) => <span>{(pageAdmin - 1) * 10 + (index + 1)}</span>,
    },
    {
      title: TEXT_DEF.text0011,
      dataIndex: 'bookmaker',
      key: 'bookmaker',
    },
    {
      title: TEXT_DEF.text0002,
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Trạng thái',
      key: 'isBlocked',
      render: (record) => <span className={`${record?.isBlocked === false ? 'text-[#1677ff]' : 'text-[#00000073]'}`}>
        {record?.isBlocked === false ? 'Active' : 'Deactive'}
      </span>,
    },
    {
      title: 'Vai trò',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Thời gian tạo',
      key: 'createdAt',
      render: (record) => moment(record?.createdAt).utcOffset(7).format('DD-MM-YYYY HH:mm:ss'),
    },
    {
      title: 'Thời gian cập nhật',
      key: 'createdAt',
      render: (record) => moment(record?.updatedAt).utcOffset(7).format('DD-MM-YYYY HH:mm:ss'),
    },
    {
      title: 'Người cập nhật',
      dataIndex: 'updatedBy',
      key: 'updatedBy',
    },
    {
      title: 'Hành động',
      key: 'action',
      render: (text, record) =>
        <div className="flex flex-col items-center justify-center">
          {
            hasRight([RIGHTS.DeleteAdminUser]) ? (
              <span className='text-[#1677ff] cursor-pointer hover:underline'
                onClick={() => handleShowModal(record.key, record?.isBlocked)}
              >
                {!record?.isBlocked === true ? 'Khóa' : 'Mở khóa'}
              </span>

            ) : null
          }
          {
            hasRight([RIGHTS.EditAdminUser]) ? (
              <div>
                <span
                  className='text-[#1677ff] cursor-pointer hover:underline'
                  onClick={() => {
                    navigate(`/account-manager/${record.key}`);
                  }}>
                  Đổi vai trò
                </span>
              </div>
            ) : null
          }
        </div>
    },
  ];

  const onChangeDate = (e, type) => {
    if (e) {
      if (type === 'from') setDateFrom(moment(e.$d).startOf('day').format('yyyy-MM-DD'));
      else setDateTo(moment(e.$d).endOf('day').format('yyyy-MM-DD'));
    } else {
      if (type === 'from') setDateFrom(null);
      else setDateTo(null);
    }
  };

  const filterOptionBm = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const disabledDate = (currentDate) => {
		const threeMonthsAgo = moment().subtract(3, "months");
    return currentDate && (currentDate < threeMonthsAgo || currentDate > moment());
	}

  const createUser = () => {
    localStorage.setItem('indexLocalCreate', indexTab);
    navigate('/account-manager/create');
  };

  // const handleDownloadExcel = async () => {
  //   const keyword = dataFilter ? dataFilter : encodeURIComponent(JSON.stringify({ role: indexTab === 1 ? 'member' : 'admin' }));

  //   await API.get(`${USER}/all?take=100000&skip=1&order=DESC&keyword=${keyword}`)
  //     .then((response) => {
  //       const res = response.data.data?.result[0];
  //       if (res) {
  //         let dataCV = [];
  //         res?.map((item) => {
  //           if (item?.role === 'member') {
  //             const user = {
  //               key: item?.id,
  //               bookmaker: item?.bookmaker?.name,
  //               username: item?.username,
  //               userId: item?.id,
  //               createdAt: item?.createdAt,
  //               isBlocked: item?.isBlocked,
  //               nickname: item?.userInfo?.nickname,
  //               isTestPlayer: item?.usernameReal === "" ? 'Chơi thật' : 'Chơi thử',
  //             };
  //             dataCV.push(user);
  //           }
  //         });

  //         const header = ['id', 'nhà cái', 'username', 'user_id', 'ngày tạo', 'khoá', 'nickname', 'loại tài khoản'];
  //         downloadExcel({
  //           fileName: 'Tài khoản',
  //           sheet: 'Sheet 1',
  //           tablePayload: {
  //             header: header,
  //             body: dataCV,
  //           },
  //         });
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       setLoading(false);
  //     });
  // };

  const renderAccAdmin = () => {
    return (
      <>
        <div className="col-12">
          <Form form={form} layout="inline" autoComplete="off" onFinish={() => search()}>
            <div className="col-12 flex" style={{ marginBottom: 15 }}>
              {
                hasRight([RIGHTS.Super]) ? (
                  <div className="col-4">
                    <Form.Item
                      name="bookmarker"
                      label={TEXT_DEF.text0011}
                      rules={[
                        {
                          required: true,
                          message: TEXT_DEF.text0004,
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Chọn nhà cái"
                        optionFilterProp="children"
                        onChange={(val) => setBookmakerSl(val)}
                        filterOption={filterOptionBm}
                        options={bookmarkers}
                        allowClear
                      />
                    </Form.Item>
                  </div>
                ) : null
              }
              <div className="col-4">
                <Form.Item
                  name="username"
                  label={TEXT_DEF.text0002}
                  rules={[
                    {
                      required: false,
                      message: TEXT_DEF.text0004,
                    },
                  ]}
                >
                  <Input style={{ width: '100%' }} />
                </Form.Item>
              </div>
            </div>
            <div className="col-12" style={{ marginBottom: 15, display: 'flex' }}>
              <div className="col-4">
                <Form.Item
                  name="dateFrom"
                  label={TEXT_DEF.text0024}
                  rules={[
                    {
                      required: false,
                      message: TEXT_DEF.text0004,
                    },
                  ]}
                >
                  <DatePicker
                    placeholder=""
                    onChange={(e) => onChangeDate(e, 'from')}
                    format={'DD-MM-YYYY'}
                    style={{ width: '100%' }}
                    disabledTime={(d) => !d || d.isSameOrAfter(dateTo ? moment(dateTo).add(1, 'd') : moment())}
                    disabledDate={disabledDateFrom}
                  />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item
                  name="dateTo"
                  label={TEXT_DEF.text0025}
                  rules={[
                    {
                      required: false,
                      message: TEXT_DEF.text0004,
                    },
                  ]}
                >
                  <DatePicker
                    placeholder=""
                    onChange={(e) => onChangeDate(e, 'to')}
                    format={'DD-MM-YYYY'}
                    style={{ width: '100%' }}
                    disabledTime={(d) => !d || d.isSameOrBefore(dateFrom ? dateFrom : null) || d.isSameOrAfter(moment())}
                    disabledDate={disabledDateTo}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="col-4">
                <Form.Item name="status" label={TEXT_DEF.text0147}>
                  <Select
                    showSearch
                    placeholder="Chọn trạng thái"
                    optionFilterProp="children"
                    onChange={(val) => setStatus(val)}
                    filterOption={''}
                    options={gameStatus}
                    allowClear
                  />
                </Form.Item>
              </div>
            <div className="payment-search">
              <Button htmlType="submit" type="primary">
                {TEXT_DEF.text0026}
              </Button>
            </div>
          </Form>
        </div>
        {
          hasRight([RIGHTS.CreateAdminUser]) ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'end',
                marginTop: '2rem',
              }}
            >
              <Button type="primary" onClick={createUser}>
                {TEXT_DEF.text0028}
              </Button>
            </div>
          ) : null
        }

        <Spin spinning={loading} delay={500} size="large">
          <div style={{ display: 'flex', width: '100%', marginTop: 20 }}>
            <Table
              key="listAdmin"
              locale={locale}
              columns={colums}
              dataSource={adminList}
              pagination={false}
              bordered style={{ width: '100%' }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              marginTop: 15,
              justifyContent: 'flex-end',
            }}
          >
            <Pagination current={page} total={totalRecordAdmin} onChange={onChangePage} defaultPageSize={10} showSizeChanger={false} showTotal={showTotal} />
          </div>
        </Spin>
      </>
    );
  };

  return (
    <div className="col-12">
      {renderAccAdmin()}
      {isShowModalConfirm && (
        <ModalCommon
          isShow={isShowModalConfirm}
          message={txtMsg.confirm}
          txtBtnAcc={TEXT_DEF.text0008}
          textBtnClose={TEXT_DEF.text0020}
          actionNext={handleBlockAccount}
          close={() => setIsShowModalConfirm(false)} />
      )}
      {isShowModalSuccess && (
        <ModalCommon
          isShow={isShowModalSuccess}
          message={txtMsg.success}
          txtBtnAcc={TEXT_DEF.text0007}
          actionNext={() => {
            setIsShowModalSuccess(false);
          }}
          close={() => {
            setIsShowModalSuccess(false);
          }}
        />
      )}
    </div>
  );
};

export default AccountAdminList;
