import React, { useState, useRef } from 'react';

const CustomInputNumber = ({ min = 0, max = 9999, step = 0.01, value, onChange }) => {
  const [inputValue, setInputValue] = useState(value || '');
  const inputRef = useRef(null);

  const handleIncrease = () => {
    if (inputValue < max) {
      let newValue = inputValue + step;
      setInputValue(newValue);
      onChange && onChange(newValue);
    }
  };

  const handleDecrease = () => {
    if (inputValue > min) {
      const newValue = inputValue - step;
      setInputValue(newValue);
      onChange && onChange(newValue);
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;

    if (value === '') {
      setInputValue('');
      onChange && onChange('');
      return;
    }

    const newValue = parseFloat(value);

    if (!isNaN(newValue) && newValue >= min && newValue <= max) {
      setInputValue(newValue);
      onChange && onChange(newValue);
    }
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <input
        type="number"
        lang="en"
        ref={inputRef}
        value={inputValue}
        onChange={handleChange}
        onInput={(e) => {
          const currentValue = parseFloat(e.target.value);
          if (currentValue > inputValue) {
            handleIncrease();
          } else if (currentValue < inputValue) {
            handleDecrease();
          }
        }}
        step={step}
        min={min}
        max={max}
        className="custom-input"
      />
    </div>
  );
};

export default CustomInputNumber;
