export const TEXT_DEF = {
  text0001: "Đăng nhập",
  text0002: "Tên đăng nhập",
  text0003: "Mật khẩu",
  text0004: "Trường này bắt buộc",
  text0005: "sai định dạng",
  text0006: "Tên đăng nhập hoặc mật khẩu không đúng",
  text0007: "Đóng",
  text0008: "Ok",
  text0009: "Hủy",
  text0010: "Quản lý nhà điều hành",
  text0011: "Nhà điều hành",
  text0012: "Game đăng ký",
  text0013: "Thời gian đăng ký",
  text0014: "Ngày cập nhật",
  text0015: "Người cập nhật",
  text0016: "Cập nhật",
  text0017: "Quay lại",
  text0018: "Tạo mới",
  text0019: "Bạn có muốn đăng xuất",
  text0020: "Không",
  text0021: "Danh sách tài khoản người chơi",
  text0022: "Tài khoản người dùng",
  text0023: "Tài khoản quản trị",
  text0024: "Từ ngày",
  text0025: "Đến ngày",
  text0026: "Tìm kiếm",
  text0027: "Xuất file excel",
  text0028: "Tạo tài khoản quản lý",
  text0029: "Chức vụ",
  text0030: "Lưu",
  text0031: "Cài đặt quyền hạn admin",
  text0032: "Quản lý UI",
  text0033: "Giao diện",
  text0034: "List game",
  text0035: "Banner",
  text0036: "Thêm",
  text0037: "Logo",
  text0038: "Icon",
  text0039: "Tên trò chơi",
  text0040: "BXH fake",
  text0041: "Tiền thắng",
  text0042: "Nội dung footer",
  text0043: "Không có dữ liệu",
  text0044: "Xem phim",
  text0045: "Livestream",
  text0046: "URL liên kết",
  text0047: "Thắng lớn",
  text0048: "Đang chơi",
  text0049: "Chơi nhiều",
  text0050: "Đại gia",
  text0051: "Trò chơi",
  text0052: "Thắng gần đây",
  text0053: "Đang chơi",
  text0054: "Đặt cược",
  text0055: "Đang tham gia",
  text0056: "Tổng cược",
  text0057: "Tạo dữ liệu fake",
  text0058: "Thống kê báo cáo",
  text0059: "Cài đặt hệ thống",
  text0060: "Tỉ lệ cược",
  text0061: "Cài đặt game",
  text0062: "Cách chơi",
  text0063: "Hướng dẫn chơi",
  text0064: "Danh sách Q&A",
  text0065: "Bảo trì",
  text0066: "Hiển thị",
  text0067: "Data hệ thống",
  text0068: "Tạo mới dữ liệu",
  text0069: "Bạn có muốn xóa data này?",
  text0070: "Tỉ lệ % lợi nhuận nhỏ nhất",
  text0071: "Bạn muốn cập nhập cài đặt?",
  text0072: "Không",
  text0073: "Cập nhập cài đặt thành công",
  text0074: "Danh sách đơn cược",
  text0075: "Tiền cược",
  text0076: "Tiền trả lại cho HV",
  text0077: "Lợi nhuận đơn cược",
  text0078: "Chi tiết",
  text0079: "Thể loại",
  text0080: "Trò chơi",
  text0081: "Cách chơi",
  text0082: "Lượt xổ",
  text0083: "Số thứ tự",
  text0084: "Số đơn cược",
  text0085: "Cấp số nhân",
  text0086: "Lợi nhuận nhà cái",
  text0087: "Ngày tạo dữ liệu",
  text0088: "Trạng thái",
  text0089: "Theo ngày",
  text0090: "Theo tháng",
  text0091: "Theo năm",
  text0092: "Số lượng đơn cược",
  text0093: "Tổng tiền cược",
  text0094: "Tổng tiền lợi nhuận",
  text0095: "Thời gian tổng hợp",
  text0096: "Loại tổng hợp",
  text0097: "Báo cáo số liệu",
  text0098: "Biều đồ thống kê",
  text0099: "Số lượng hội viên tham gia",
  text0100: "Game yêu thích",
  text0101: "Biểu đồ lợi nhuận",
  text0102: "Dãy số trúng",
  text0103: "Lịch sử ví HV",
  text0104: "Số tiền vào sảnh",
  text0105: "Số tiền đã cược",
  text0106: "Số tiền hiện tại",
  text0107: "Số tiền cập nhật ví sau cùng",
  text0110: "Số tiền giữ lại tại sảnh",
  text0111: "Lịch sử thắng/thua",
  text0112: "Thời gian vào sảnh",
  text0113: "Thời gian cập nhật ví sau cùng",
  text0114: "Số dư ví",
  text0115: "Số lượng hội viên đăng nhập",
  text0116: "Quản trị viên",
  text0117: "Quản trị viên nhà cái",
  text0118: "Bạn chắc chắn muốn xóa dữ liệu này?",
  text0119: "Bạn đã xóa thành công!",
  text0120: "Bạn chắc chắn muốn {} HV này?",
  text0121: "Hv đã {} thành công!",
  text0122: "Chi tiết cách chơi",
  text0123: "Dữ liệu tổng hợp",
  text0124: "Số lượng hội viên",
  text0125: "Số lượng đơn cược",
  text0126: "Tiền cược",
  text0127: "Lợi nhuận",
  text0128: "Lịch sử dòng tiền",
  text0129: "Số tiền",
  text0130: "Thời gian",
  text0131: "Tổng tiền HV thắng",
  text0132: "Tổng tiền HV thua",
  text0133: "Tổng số lượng đơn cược",
  text0134: "Số dư hiện tại",
  text0135: "Thông tin tổng hợp HV",
  text0136: "Tổng hợp Xổ Số Nhanh",
  text0137: "Tổng hợp từng loại Xổ Số Nhanh",
  text0138: "Lịch sử Originals",
  text0139: "Video Poker",
  text0140: "Hilo",
  text0141: "Kết quả lượt đầu",
  text0142: "Kết quả lượt cuối",
  text0143: "Lá bài",
  text0144: "Dự đoán",
  text0145: "Xổ số nhanh",
  text0146: "Tra cứu kết quả xổ số nhanh",
  text0147: "Trạng thái",
  text0148: "Kiểu tài khoản",
  text0149: "Cài đặt",
  text0150: "Cài đặt thông số xổ số nhanh",
  text0151: "Keno",
  text0152: "Dễ",
  text0153: "Cơ bản",
  text0154: "Trung bình",
  text0155: "Cao",
  text0156: "Hiển thị thêm",
  text0157: "Rút gọn",
  text0158: "Đặt",
  text0159: "Trúng $ số",
  text0160: "Thời gian",
  text0161: "Danh sách tài khoản 90 ngày gần nhất",
  text0162: "Vui lòng nhập địa chỉ Ipv4",
  text0163: "Sai định dạng địa chỉ IpV4",
  text0164: "Địa chỉ IP",
  text0165: "Ghi chú",
  text0166: "Thông tin đặt cược",
  text0167: "Số cược",
  text0168: "Số nhân",
  text0169: "Số tiền của một số cược",
  text0170: "Bạn có muốn xoá địa chỉ IP này không?",
  text0171: "Thêm mới",
  text0172: "Ngày giờ bắt đầu không thể lớn hơn ngày giờ kết thúc!",
  text0173: "Tạo mới chủ đề",
  text0174: "Tạo mới Q&A",
  text0175: "Chủ đề lớn",
  text0176: "Chủ đề con",
  text0177: "Bạn có chắc chắn $",
  text0178: "Đồng ý",
  text0179: "Tạo mới chủ đề thành công",
  text0180: "Mời bạn chọn chủ đề",
  text0181: "Câu hỏi",
  text0182: "Trả lời",
  text0183: "Thêm mới chủ đề chưa tồn tại",
  text0184: "Thêm mới chủ đề con dựa trên chủ đề lớn đã tồn tại",
  text0185: "Tạo mới Q&A thành công",
  text0186: "Tên hội viên mã hoá",
  text0187: "Chỉnh sửa chủ đề",
  text0188: "Chỉnh sửa Q&A",
  text0189: "tạo chủ đề mới không",
  text0190: "tạo câu hỏi mới không",
  text0191: "chỉnh sửa chủ đề không?",
  text0192: "chỉnh sửa câu hỏi không?",
  text0193: "Originals banner",
  text0194: "Lottery banner",
  text0195: "Chủ đề đã tồn tại",
  text0196: "Danh sách trò chơi XSTT",
  text0197: "Xổ số truyền thống",
  text0198: "Tỉnh/Thành phố",
  text0199: "Danh sách trò chơi Originals",
  text0200: "Khó",
  text0201: "Quản lý trả thưởng",
  text0202: "Số tiền cược",
  text0203: "Số tiền trúng dự kiến",
  text0204: "Ngày trả thưởng",
  text0205: "Giới hạn đơn trong ngày",
  text0206: "Tổng giá trị giải thưởng",
  text0207: "Thấp",
  text0208: "Chỉ cho phép số, dấu . và dấu ,",
  text0209: "Không đủ số tỉ lệ",
  text0210: `Ví dụ cho trường hợp Level “Thấp”, 10 hàng có các tỉ lệ 0,1.2,1.2,1.2,1.2,0,1.2,1.2,1.2,1.5 
tương ứng với các phần của vòng quay như sau`,
};

export const EMPTY_DATA = {
  emptyText: "Không có dữ liệu",
};
// bonus config
export const LABELS_BONUS_CONFIG = {
  From: 'Từ',
  To: 'Đến',
  NumberOfUser: 'Số lượng user tham gia',
  Percent: 'Phần trăm bonus',
};
export const LABEL_CREATE = "Tạo mới dữ liệu";
export const TITLE_BONUS_CONFIG = "Cài đặt tiền thưởng";
export const TITLE_BONUS_CONFIG_CREATE = "Tạo bonus config";
export const UPDATE_SUCCESS = 'Cập nhật thành công.';
export const UPDATE_FAIL = 'Cập nhật thất bại.';
export const CREATE_SUCCESS = 'Tạo thành công.';
export const CREATE_FAIL = 'Tạo thất bại.';
export const GET_DATA_FAIL = 'Xử lý dữ liệu không thành công.';
export const DELETE_SUCCESS = 'Xoá thành công';
export const DELETE_FAIL = 'Xoá thất bại';
export const TIME_RESET_BONUS_DEFAULT = '3';
export const LABELS_RANK = {
  name: 'Xếp hạng',
  limitAmount: 'Số tiền đặt cược lớn nhất',
};