import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  DatePicker,
  Table,
  Spin,
  Pagination,
  Select,
  Modal,
} from "antd";
import { PlayCircleOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import moment from "moment";
import "moment-timezone";
import * as _ from "lodash";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, BarElement } from 'chart.js';

import API, { BOOKMAKER, GAME, STATISTIC_ORDERS } from "../../../network/api";
import { TEXT_DEF, EMPTY_DATA } from "../../../constant/text";
import { getGame, extractNumbers, getGameNameXSTT, getTxtRewardPoker } from "../../../components/until";
import ModalChart from "../modal";
import { OP_CATEGORY, OP_GAME_ORIGINALS } from "../constant";
import { RIGHTS } from "../../../constant/rights";
import { hasRight } from "../../../components/until/right";
import { CopyOutlined } from '@ant-design/icons';
import dayjs from "dayjs"
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import {disabledTimeFrom, disabledTimeTo, validateDateRange} from "../../../utils/dateUtils";
import { dataProvinces } from "../../../constant/province";

import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";

ChartJS.register(ArcElement, Tooltip, Legend, BarElement);

const ReportByOrder = () => {
  const lotteryGameStatus = [
    {
      key: 0,
      value: '',
      label: 'Tất cả',
    },
    {
      key: 1,
      value: 'pending',
      label: 'Đang mở',
    }, {
      key: 2,
      value: 'closed',
      label: 'Đã đóng',
    },
    {
      key: 3,
      value: 'canceled',
      label: 'Đã huỷ',
    },
    {
      key: 4,
      value: 'warning',
      label: 'Cảnh báo',
    },
  ];

  const casinoGameStatus = [
    {
      key: 0,
      value: '',
      label: 'Tất cả',
    },
    {
      key: 1,
      value: 'pending',
      label: 'Đang mở',
    }, {
      key: 2,
      value: 'closed',
      label: 'Đã đóng',
    },
    {
      key: 3,
      value: 'canceled',
      label: 'Đã huỷ',
    },
  ];
  localStorage.removeItem("indexTabAcc");
  const auth = JSON.parse(localStorage.getItem("auth"));
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const navigate = useNavigate();
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [indexTab, setIndexTab] = useState(1);
  const [loading, setLoading] = useState(false);
  const [typeSl, setTypeSl] = useState();
  const [dataChartModal, setDataChartModal] = useState(undefined);
  const [titleChartModal, setTitleChartModal] = useState(undefined);
  const [isShowModalChart, setIsShowModalChart] = useState(false);
  const [opGameCategory, setOpGameCategory] = useState(OP_CATEGORY);
  const [gameCategory, setGameCategory] = useState();
  const [game, setGame] = useState();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const [status, setStatus] = useState('');
  const [gameTypes, setGameTypes] = useState();
  const [bookmarkers, setBookmarkers] = useState();
  const [bookmarkerSelected, setBookmarkerSelected] = useState();
  const [ordersInfo, setOrdersInfo] = useState([]);
  const [gameStatus, setGameStatus] = useState(lotteryGameStatus);
  const [open, setOpen] = useState(false);
  const [orderDetail, setOrderDetail] = useState({});
  const [finalInfo, setFinalInfo] = useState({
    allBet: 0,
    allAmountUserWin: 0,
    allProfit: 0,
  });
  const [orderId, setOrderId] = useState(undefined);

  const colums = [
    {
      title: "STT",
      align: "center",
      key: "order",
      render: (text, record, index) => <span>{(page - 1) * pageSize + (index + 1)}</span>,
    },
    {
      title: "Mã đơn cược",
      key: "code",
      render: (record) => (
        <div>
          <div className="flex w-full items-center justify-center">
            <div style={{ cursor: "pointer", color: "#1677ff" }} onClick={() => showModal(record.id)}>
              {record.code}
            </div>
            {record?.code ? <CopyOutlined className="copy-icon" onClick={() => copyToClipboard(record?.code)} /> : ""}
          </div>
        </div>
      ),
    },
    {
      title: "Bắt đầu cược",
      dataIndex: "openTime",
      key: "openTime",
    },
    {
      title: "Kết thúc cược",
      dataIndex: "closeTime",
      key: "closeTime",
    },
    {
      title: TEXT_DEF.text0011,
      dataIndex: "bookmarkerName",
      key: "bookmakerName",
    },
    {
      title: "Tên HV mã hóa",
      key: "username",
      render: (record) => (
        <div>
          <div className="flex w-full items-center justify-center">
            <div>{record.username}</div>
            {record.username ? <CopyOutlined className="copy-icon" onClick={() => copyToClipboard(record?.username)} /> : ""}
          </div>
        </div>
      ),
    },
    // {
    //   title: "Nền tảng",
    //   dataIndex: "device",
    //   key: "device",
    // },
    {
      title: "Thể loại",
      key: "game",
      render: (record) => (record.game == "lottery" ? "Xổ số" : "Game Việt"),
    },
    {
      title: "Trò chơi",
      dataIndex: "gameType",
      key: "gameType",
    },
    {
      title: "Tiền cược",
      dataIndex: "revenue",
      key: "revenue",
    },
    {
      title: "Tiền khách thắng",
      // dataIndex: "paymentWin",
      key: "paymentWin",
      render: (record) => {
        if (record.status == "Đang mở" && record.game != 'lottery') {
          return '----'; 
        }
        return record.paymentWin;  
      },
    },
    // {
    //   title: "Tiền khuyễn mãi",
    //   dataIndex: "bonus",
    //   key: "bonus",
    // },
    {
      title: "Lợi nhuận nhà điều hành",
      // dataIndex: "profit",
      key: "profit",
      render: (record) => {
        if (record.status == "Đang mở" && record.game != 'lottery') {
          return '----'; 
        }
        return record.profit;  
      },
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
    },
  ];

  const  fallbackCopyToClipboard = (text) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    try {
      document.execCommand('copy');
      alert('Sao chép thành công');
    } catch (err) {
      alert('Sao chép thất bại: ', err);
    }
    document.body.removeChild(textArea);
}

const disabledDateFrom = (current) => {
  const isAfterEndOfDay = (current) => current && current > dayjs().endOf("day");
  const isBeforeStartOfDateTo = (current, months) => current < dayjs(dateTo).subtract(months, "months").startOf("day");
  const isAfterEndOfDateTo = (current) => current > dayjs(dateTo).endOf("day");
  if (!current) return false;

  const monthsToSubtract = 3;

  if (!dateTo) {
    return isAfterEndOfDay(current);
  }

  return isAfterEndOfDay(current) || isBeforeStartOfDateTo(current, monthsToSubtract) || isAfterEndOfDateTo(current);
};

const disabledDateTo = (current) => {
  const isAfterEndOfDay = (current) => current && current > dayjs().endOf("day");
  const isBeforeStartOfDateFrom = (current) => current < dayjs(dateFrom).startOf("day");
  const isAfterEndOfDateFrom = (current, months) => current > dayjs(dateFrom).add(months, "months").endOf("day");
  if (!current) return false;

  const monthsToAdd = 3;

  if (!dateFrom) {
    return isAfterEndOfDay(current);
  }

  return isAfterEndOfDay(current) || isBeforeStartOfDateFrom(current) || isAfterEndOfDateFrom(current, monthsToAdd);
};

const copyToClipboard = (text) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(text)
            .then(() => {
                alert('Sao chép thành công');
            })
            .catch(err => {
              alert('Sao chép thất bại: ', err);
            });
    } else {
        fallbackCopyToClipboard(text);
    }
}
  useEffect(() => {
    forceUpdate({});
    getBookmarkers();
  }, []);

  useEffect(() => {
    form.setFieldValue("gameType", undefined)
    setGame(undefined);
    if (gameCategory === "lottery") {
      getGameTypes(0);
    } else if (gameCategory === "casino") {
      getGameTypes(1);
    } else {
      getGameTypes(-1);
    }
  }, [gameCategory]);

  useEffect(() => {
    if (!game) return;

    if (
      game === "hilo"
      || game === "keno"
      || game === "video-poker"
      || game === "mines"
    ) {
      setGameStatus(casinoGameStatus);
    } else {
      setGameStatus(lotteryGameStatus);
    }
  }, [game]);

  useEffect(() => {
    getOrders();
  }, [page, pageSize]);

  const getGameTypes = async (type = null) => {
    const result = await API.get(`${GAME}/all/${type}`);
    if (!result?.data?.data?.result || result?.data?.data?.result.length === 0) {
      setGameTypes([]);
      return;
    };

    const gameTypesXSN = (result?.data?.data?.result || []).map((item) => {
      return {
        key: item?.id,
        value: item?.category == "xoso" ? `${item?.parrentType}-${extractNumbers(item?.type)}` : item?.parrentType,
        label: getGame(item?.parrentType, extractNumbers(item?.type)),
      };
    });
    const gameTypesXSTT = await getGameTypesXSTT();
    let allGames = gameTypesXSN;
    if (type !== 1) {
      allGames = [...gameTypesXSN, ...gameTypesXSTT];
    }
    setGameTypes(allGames);
  }

  const getGameTypesXSTT = async () => {
    const result = await API.get(`${GAME}/xstt`);
    if (!result?.data?.data || result?.data?.data?.length === 0) {
      return [];
    }

    const games = result?.data?.data || [];
    const { gamesMT, gamesMB, gamesMN } = games.reduce((init, currentValue) => {
      if (currentValue.parrentType === 'xsmt') {
        init.gamesMT.push(currentValue);
      } else if (currentValue.parrentType === 'xsmn') {
        init.gamesMN.push(currentValue);
      } else {
        init.gamesMB.push(currentValue);
      }

      return init;
    }, { gamesMT: [], gamesMB: [], gamesMN: [] });
    const gamesFinal = gamesMB.concat(gamesMT).concat(gamesMN);

    return gamesFinal.map((item) => {
      return {
        key: item?.id,
        value: `${item.lotteryType}_${item.parrentType}_${item.type}`,
        label: item.textView.includes('XS') ? item.textView.replace('XS', 'Xổ Số') : item.textView,
      };
    });
  };

  const initFormValues = (initData) => {
    if (!initData) return;

    setGameCategory("all");
    for (const key in initData) {
      form.setFieldsValue({
        [key]: initData[key],
        gameCategory: "all",
        status: '',
      });
    }
  }

  const getBookmarkers = async () => {
    const result = await API.get(`${BOOKMAKER}/all`);
    const bookmarkers = result.data?.data?.result[0] || [];
    if (!bookmarkers || bookmarkers.length === 0) return;

    const tempBookmarkers = bookmarkers?.map((item) => {
      return {
        key: item?.id,
        value: item?.id,
        label: item?.name,
      };
    });
    setBookmarkers(tempBookmarkers);
    setBookmarkerSelected(tempBookmarkers?.[0]?.key);
    initFormValues({
      bookmarker: tempBookmarkers?.[0]?.label,
    });
  };

  const getParams = (isExport = false) => {
    if (!gameCategory || !bookmarkerSelected) return;
    let params = "";
    if (bookmarkerSelected) {
      params += `bookmarkerId=${bookmarkerSelected}`;
    }
    if (dateFrom) {
      params += `&fromDate=${moment(dateFrom).unix()}`;
    }
    if (dateTo) {
      params += `&toDate=${moment(dateTo).unix()}`;
    }

    let check = false;

    if (game && game?.length > 0) {

      params += `&gameType=${game}`;

      if (game.indexOf('xs') > -1) {
        params += `&game=lottery`;
      } else {
        params += `&game=casino`;

      }
      check = true;
    }

    if (gameCategory && !check) {
      params += `&game=${gameCategory}`;
      if (!game || game?.length === 0) {
        const dataGame = gameTypes.map(obj => obj.value).join(',');
        params += `&gameType=${dataGame}`;
      }
    }

    if (status) {
      params += `&status=${status}`;
    }
    // params += !isExport ? `&page=${page}&limit=${pageSize}` : `&page=1&limit=10000000`;

    const username = form.getFieldValue('username');
    if (username) {
      params += `&username=${username.trim()}`;
    }
    const orderId = form.getFieldValue('orderId');
    if (orderId) {
      params += `&orderId=${orderId}`;
    }

    return params;
  }

  const getOrders = async () => {
    try {
      let params = getParams();

      if (!params) return;
      params += `&page=${page}&limit=${pageSize}` 


      setLoading(true);
      const result = await API.get(`${STATISTIC_ORDERS}?${params}`);
      const ordersInfo = result.data?.data?.orders || [];
      const total = result.data?.data?.total || 0;
      const finalResult = [];
      setTotalRecord(total);

      // let allBet = 0;
      // let allAmountUserWin = 0;
      // let allProfit = 0;

      for (const item of ordersInfo) {
        const profit = Number(item.bookmarkerProfit);
        const percentProfit = Number(((profit / Number(item.totalBet || 0)) * 100) || 0).toFixed(2);
        let dataTypegame = item?.gameType;
        const lotteryGame = item?.gameType?.split('_')[0];
        let lotteryType = "xsn";
        if (lotteryGame !== 'xstt') {
          if (dataTypegame?.indexOf("-") > -1 && dataTypegame.indexOf('poker') < 0) {
            const parrentType = dataTypegame.substring(0, dataTypegame?.indexOf("-"));
            const seconds = dataTypegame.substring(dataTypegame?.indexOf("-") + 1);
            dataTypegame = getGame(parrentType, seconds);
          }
        } else {
          // const districts = dataProvinces[0].all;
          // if (dataTypegame.includes('xstt_xsmb_')) {
          //   dataTypegame = dataTypegame.replace(/^xstt_xsmb_[^_]+/, 'Xổ Số Miền Bắc');
          // } else {
          //   const formattedText = dataTypegame.replace(/^xstt_[^_]+_/, '');
          //   dataTypegame = `Xổ Số ${districts[formattedText]}`;
          // }
          lotteryType = 'xstt';
          const province = item?.gameType?.split('_')[2];
          dataTypegame = getGameNameXSTT(province);
        }
        let status;
        switch (item.status) {
          case "closed": status = "Đã đóng"; break;
          case "pending": status = "Đang mở"; break;
          case "canceled": status = "Đã hủy"; break;
          case "warning": status = "Cảnh báo"; break;
          default: status = "Đã đóng"; break;
        }

        finalResult.push({
          bookmarkerName: item.bookMakerName,
          closeTime: new Date(item.closeTime).toLocaleString("vi-vn", { hour12: false }),
          game: item.game,
          gameType: dataTypegame,
          id: item.id,
          openTime: new Date(item.openTime).toLocaleString("vi-vn", { hour12: false }),
          paymentWin: dollarUS.format(Number(item.paymentWin)),
          revenue: dollarUS.format(Number(item.revenue)),
          status: status,
          username: item?.username,
          profit: dollarUS.format(item.profit),
          code: item.code,
          lotteryType,
        });

        // allBet += Number(item.revenue) || 0;
        // allAmountUserWin += Number(item.paymentWin) || 0;
        // allProfit += Number(item.profit);
      }

      setFinalInfo({
        allBet: result?.data?.data?.orderInfo?.revenue || 0,
        allAmountUserWin: result?.data?.data?.orderInfo?.paymentWin || 0,
        allProfit: result?.data?.data?.orderInfo?.profit || 0,
      });
      setOrdersInfo(finalResult);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  const onSearch = () => {
    setPage(1)
    getOrders();
  };

  const dollarUS = Intl.NumberFormat("en-US");

  const onChangeDate = (e, type) => {
    if (e) {
      if (type === "from") setDateFrom(moment(e.$d).format('yyyy-MM-DD HH:mm:ss'));
      else setDateTo(moment(e.$d).format('yyyy-MM-DD HH:mm:ss'));
    } else {
      if (type === "from") setDateFrom(null);
      else setDateTo(null);
    }
  };

  const filterOptionBm = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const disabledDate = (currentDate) => {
		const threeMonthsAgo = moment().subtract(3, "months");
    return currentDate && (currentDate < threeMonthsAgo || currentDate > moment());
	}

  const convertResult = (text) => {
    return text.replaceAll(`"`, "").replace("[", "").replace("]", "").replaceAll(",", ", ");
  }
  const getUserProfit = (item) => {
    let result;
    if (item?.status === "closed") {
      result = Number(item?.revenue) + Number(item?.paymentWin);
    } else if (item?.status === "canceled") {
      result = item?.revenue;
    } else {
      result = 0;
    }
    return result;
  }
  const onExportExl = async () => {
    try {
      let params = getParams(true);
      if (!params) return;
      params +=  `&page=1&limit=10000000`
      
       params += '&mode=download';
      setLoading(true);
      const result = await API.get(`${STATISTIC_ORDERS}?${params}`);
      const ordersInfo = result.data?.data?.orders || [];
      const total = result.data?.data?.total || 0;
			let finalResult = [];
      
      setTotalRecord(total);
      for (const item of ordersInfo) {
        let dataTypegame = item?.gameType;
        if (dataTypegame?.indexOf("-") > -1 && dataTypegame.indexOf('poker') < 0) {
          const parrentType = dataTypegame.substring(0, dataTypegame?.indexOf("-"));
          const seconds = dataTypegame.substring(dataTypegame?.indexOf("-") + 1);
          dataTypegame = getGame(parrentType, seconds);
        }

        const lotteryGame = item?.gameType?.split('_')[0];
        let lotteryType = '';
        if (lotteryGame !== 'xstt') {
          lotteryType = "xsn";
          if (dataTypegame?.indexOf("-") > -1 && dataTypegame.indexOf('poker') < 0) {
            const parrentType = dataTypegame.substring(0, dataTypegame?.indexOf("-"));
            const seconds = dataTypegame.substring(dataTypegame?.indexOf("-") + 1);
            dataTypegame = getGame(parrentType, seconds);
          }
        } else {
          lotteryType = 'xstt';
          const province = item?.gameType?.split('_')[2];
          dataTypegame = getGameNameXSTT(province);
        }

        let status;
        let returnMoney = 0;
        let betSubType = '';
        if(item.paymentWin > 0) {
          returnMoney = Number(item.paymentWin) + Number(item.revenue)
        }
        
        switch (item.status) {
          case "closed": status = "Đã đóng"; break;
          case "pending": status = "Đang mở"; break;
          case "canceled": status = "Đã hủy"; break;
          case "warning": status = "Cảnh báo"; break;
          default: status = item.status; break;
        }
          
        if(item.gameType == 'keno') {
          const detailArray = (item.detail || '').split(','); 
          const kenoHitBetArray = (item.kenoHitBet|| '').split(','); 
          const matchingNumbers = kenoHitBetArray.filter(num => detailArray.includes(num));
          const matchingNumbersString = matchingNumbers.join(',');
          item.betWinResult = matchingNumbersString;
        } else if(item.gameType == 'hilo') {
          const hiloArray = (item.hilo|| '').split(','); 
          const betWinResultArray = (item.betWinResult|| '').split(','); 
          if(betWinResultArray.length > 0 && item.status !== 'canceled')  {
            item.multi = betWinResultArray[betWinResultArray.length - 1]
          }
          const matchedResults = hiloArray.map((value, index) => {
            const betWinValue = betWinResultArray[index]; // Lấy giá trị tương ứng từ betWinResult
            switch (value.trim()) {
              case '1':
                return `${betWinValue} 🔼`; // Thêm dấu 🔼 nếu là 1
              case '0':
                return `${betWinValue} =`;  // Thêm dấu = nếu là 0
              case '-1':
                return `${betWinValue} 🔽`; // Thêm dấu 🔽 nếu là -1
              default:
                return betWinValue; // Trả về giá trị gốc nếu không có biểu tượng
            }
          });
          item.betWinResult = '▶️, ' + matchedResults.join(', ');
          if(item.status == 'canceled') {
            returnMoney = item.revenue
          }
        } else if(item.gameType == 'video-poker') {
          item.betWinResult = getTxtRewardPoker(item.betWinResult);
          if(!item.multi) {
            item.multi = '0.00'
          }
        }

        if(item.gameType == 'mines') {
          // if(item.betWinResult != 'null') { // trúng
          //   item.detail = item.minesBet?.split(',');
          //   item.detail = item.detail?.length + '💎';
          // } else {
          //   let str = item?.minesBet;
          //   let arr = str?.split(',');
          //   item.detail = arr.length - 1 + '💎' + 1 +'💣'
          // }
          if(Number(item.multi) > 0) {
            item.detail = item.minesBet?.split(',');
            item.detail = item.detail?.length + '💎';
          } else {
              let str = item?.minesBet;
              let arr = str?.split(',');
              item.detail = arr.length - 1 + '💎 ' + 1 +'💣'
          }
          item.betWinResult = '';
          if(item.status == 'canceled') {
            item.detail = '';
            returnMoney = item.revenue;
          }
        }

        if(item.game == 'lottery') {
          if(item.status == 'canceled') {
            returnMoney = item.revenue;
          }
          const lotteryGame = item.gameType.split('_')[0];
          if(lotteryGame == 'xstt') {
            const province = item?.gameType?.split('_')[2];
            dataTypegame = getGameNameXSTT(province);
            item.gameType = dataTypegame;
          } else {
            const parrentType = item.gameType.substring(0, item.gameType?.indexOf("-"));
            const seconds = item.gameType.substring(item.gameType?.indexOf("-") + 1);
            item.gameType = getGame(parrentType, seconds);
          }
        }
        
        
        if(item.game == 'casino') {
          if(item.gameType == 'keno') {
            betSubType = getKenoType(item.typeBet)
            returnMoney = Number(item.multi) * Number(item.revenue)
          } else if (item.gameType == 'mines') {
            betSubType = item.typeBet.split(',').length + ' mìn'
          } else if (item.gameType == 'video-poker') {
            returnMoney = Number(item.multi) * Number(item.revenue)
          }
        } else if(item.game == 'lottery') {
          betSubType = item.typeBet
				}
				
				if (item.gameType === 'tower') {
					item.betWinResult = '';
					betSubType = transformTypeTower(item.typeBet);
          if (item.status == 'canceled') {
            returnMoney = item.revenue;
          }
				}

        if (item.gameType === 'diamond') {
          const detail = item.detail;
          const numbers = detail ? detail.split(',') : [];
          const frequency = {};

          numbers.forEach(num => {
            frequency[num] = (frequency[num] || 0) + 1;
          });

          let result = '';
          const icon = '💎';
          for (const [num, count] of Object.entries(frequency)) {
            if (count > 1) {
              result += `${count}${icon}`;
            }
          }
          item.betWinResult = Number(item.betWinResult).toFixed(1) + 'x';
          item.multi = Number(item.multi).toFixed(1);
          item.detail = result;
          returnMoney = Number(item.paymentWin) + Number(item.revenue);
        }

        if (item.gameType === 'coinflip') {
          returnMoney = Number(item.paymentWin) + Number(item.revenue);
          item.multi = Number(item.multi).toFixed(1);
          item.betWinResult = item.multi + 'x';
        }

        if (item.gameType === 'wheel') {
          returnMoney = Number(item.paymentWin) + Number(item.revenue);
          item.multi = Number(item.multi).toFixed(1);
          item.betWinResult = item.multi + 'x';
          let labelTypeBet = '';
          if (item.typeBet === 'easy') {
            labelTypeBet = 'thấp';
          } else if (item.typeBet === 'normal') {
            labelTypeBet = 'trung bình';
          } else if (item.typeBet === 'hard') {
            labelTypeBet = 'cao';
          }
          betSubType = `${labelTypeBet} - ${item.detail} hàng`;
          item.detail = '';
        }

        finalResult.push({
          bookmarkerName: item.bookMakerName, // 1
          username: item.username, // 1
          game: item.game !== 'casino' ? 'Xổ số': 'Game Việt', // 1
          betType: item.game == 'casino' ? `VNTOP ORIGINALS ${item.gameType}` : `${item.gameType}`, // 1
          betSubType: betSubType, // 1 cách chơi
          turnIndex: item.game == 'casino' ? '' : item.turnIndex, // 1 lượt xổ
          code: item.code, // 1 mã đơn cược
          detail: (item.game === 'casino' && item.gameType === 'tower') ? (item.status !== 'canceled' ? detailBetTower (item, 'export') : '') : item.detail, // chi tiết
          betWinResult: item.betWinResult && item.betWinResult != 'null'  ? item.betWinResult?.replace(/[\[\]"]/g, '') : '',
          multi: item.multi == null || item.game == 'lottery' ? '' : item.multi,
          revenue: item.revenue,
          profit: item.profit * -1,
          returnMoney: returnMoney,
          bookmakerProfit: item.profit,
          status: status,
          created_at: (new Date(item.openTime)).toLocaleString('en-GB', {
            hour12: false
          }),
          updated_at: (new Date(item.closeTime)).toLocaleString('en-GB', {
            hour12: false
          }),
        
        });
      }
      exportToExcel(finalResult)
      setLoading(false);
      getOrders()
    } catch (err) {
      console.log(err)
      setLoading(false);
    }
  }

  const exportToExcel = (data) => {
    const name = "Thống kê theo đơn cược"
    const formattedData = data.map(item => ({
      'Nhà điều hành': item.bookmarkerName,
      'Tên HV mã hóa': item.username,
      'Thể loại': item.game,
      'Trò chơi': item.betType,
      'Cách chơi': item.betSubType,
      'Lượt xổ': item.turnIndex,
      'Mã đơn cược': item.code,
      'Chi tiết': item.detail,
      'Dãy số trúng': item.betWinResult ? item.betWinResult : '', 
      'Hệ số thanh toán':  item.multi ? item.multi + 'x' : item.multi,
      'Tiền cược': item.revenue || 0,
      'Lợi nhuân đơn cược': item.profit || 0,
      'Trả lại cho HV': item.returnMoney || 0,
      'Lợi nhuận nhà điều hành': item.bookmakerProfit || 0,
      'Trạng thái': item.status,
      'Bắt đầu cược': item.created_at,
      'Kết thúc cược': item.updated_at
    }));

    const ws = XLSX.utils.json_to_sheet(formattedData);
    const columnFormats = [
      { header: 'Nhà điều hành', key: 'Nhà điều hành' },
      { header: 'Tên HV mã hóa', key: 'Tên HV mã hóa' },
      { header: 'Thể loại', key: 'Thể loại' },
      { header: 'Trò chơi', key: 'Trò chơi' },
      { header: 'Cách chơi', key: 'Cách chơi' },
      { header: 'Lượt xổ', key: 'Lượt xổ' },
      { header: 'Mã đơn cược', key: 'Mã đơn cược' },
      { header: 'Chi tiết', key: 'Chi tiết' },
      { header: 'Dãy số trúng', key: 'Dãy số trúng' },
      {header: 'Hệ số thanh toán', key: 'Hệ số thanh toán'},
      { header: 'Tiền cược', key: 'Tiền cược', type: 'n' },
      { header: 'Lợi nhuân đơn cược', key: 'Lợi nhuân đơn cược', type: 'n' },
      { header: 'Trả lại cho HV', key: 'Trả lại cho HV', type: 'n' },
      { header: 'Lợi nhuận nhà điều hành', key: 'Lợi nhuận nhà điều hành', type: 'n' },
      { header: 'Trạng thái', key: 'Trạng thái' },
      { header: 'Bắt đầu cược', key: 'Bắt đầu cược' },
      { header: 'Kết thúc cược', key: 'Kết thúc cược' },
    ];

    formattedData.forEach((row, rowIndex) => {
      columnFormats.forEach((col, colIndex) => {
        const cellRef = XLSX.utils.encode_cell({ c: colIndex, r: rowIndex + 1 });
        if (col.type === 'n') {
          ws[cellRef].t = 'n';
        }
      });
    });

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    saveAs(new Blob([wbout], { type: 'application/octet-stream' }), `${name}.xlsx`);
  };

  const showModal = async (id) => {
    const order = ordersInfo.find(ord => ord.id === id);

    if (!order) {
      return;
    }
    const result = await API.get(`${STATISTIC_ORDERS}/${order.id}?gameType=${order.gameType}&game=${order.game}&lotteryType=${order.lotteryType}`);
    const orderDetail = _.get(result, 'data.data', {});
    if (orderDetail.game === "Xổ số") {
      orderDetail.awardDetail = JSON.parse(orderDetail.awardDetail);
    }

    setOrderDetail(orderDetail);
    setOpen(true);
  };

  const hideModal = () => {
    setOpen(false);
  };

  const getStatus = (orderDetail) => {
    if (!orderDetail) return;

    let status = '';
    if (orderDetail.status === "closed") {
      status = 'Đã đóng';
    } else if (orderDetail.status === "pending") {
      status = 'Đang mở';
    } else if (orderDetail.status === "canceled") {
      status = 'Đã huỷ';
    } else if (orderDetail.status === "warning") {
      status = 'Cảnh báo';
    }

    return status;
  }

  const transformInfo = (detail) => {
    switch(detail) {
      case 'chan':
        return 'Chẵn';
      case 'le': 
        return 'Lẻ';
      case 'tai':
        return 'Tài';
      case 'xiu':
        return 'Xỉu';
      case 'tong-tai':
        return 'Tổng tài';
      case 'tong-xiu':
        return 'Tổng xỉu';
      case 'tong-1':
        return 'Tổng 1';
      case 'tong-1':
        return 'Tổng 1';
      case 'tong-2':
        return 'Tổng 2';
      case 'tong-3':
        return 'Tổng 3';
      case 'tong-4':
        return 'Tổng 4';
      case 'tong-5':
        return 'Tổng 5';
      case 'tong-6':
        return 'Tổng 6';
      case 'tong-7':
        return 'Tổng 7';
      case 'tong-8':
        return 'Tổng 8';
      case 'tong-9':
        return 'Tổng 9';
      case 'tong-10':
        return 'Tổng 10';
      case 'tong-11':
        return 'Tổng 11';
      case 'tong-12':
        return 'Tổng 12';
      case 'tong-13':
        return 'Tổng 13';
      case 'tong-14':
        return 'Tổng 14';
      case 'tong-15':
        return 'Tổng 15';
      case 'tong-16':
        return 'Tổng 16';
      case 'tong-17':
        return 'Tổng 17';
      case 'tong-18':
        return 'Tổng 18';
      case 'tong-le':
        return 'Tổng lẻ';
      case 'tong-chan':
        return 'Tổng chẵn';
      default:
        return detail;
    }
  };
  const transformOrginalType = (type) => {
    switch(type) {
      case 'video-poker': return 'Video Poker';
      case 'coinflip': return 'CoinFlip';
      case 'diamond': return 'Diamond';
      case 'wheel': return 'Wheel';
      case 'limbo': return 'Limbo';
      case 'tower': return 'Tower';
      case 'mines': return 'Mines';
      case 'hilo': return 'Hilo';
      default: return type;
    }
  }

  const calculateAverageRevenuePerBet = (revenue, numberOfBet, multiple) => {
    const parseRevenue = parseFloat(revenue);
    const result = parseRevenue / numberOfBet / multiple;
    const formattedNumber = result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return formattedNumber;
  };

  const renderDetail = (label, value) => (
    <div className="custom-break">
      <label className="label">{label}: </label> {value}
    </div>
  );

  const getKenoType = (type) => {
    switch(type) {
      case 'easy':  return 'Dễ';
      case 'normal':  return 'Trung bình';
      case 'basic':  return 'Cơ bản';
      case 'high':  return 'Khó';
      default: return type;

    }
  }

  const transformTypeTower = (type) => {
    const typeMapping = {
      easy: 'Dễ',
      basic: 'Cơ bản',
      medium: 'Trung bình',
      hard: 'Khó',
      extreme: 'Rất khó',
    };

    return typeMapping[type] || type;
  };

  const detailBetTower = (detail, type) => {
    if (detail.status !== 'canceled') {
      let totalEdge = 0;
      let skull = '';

      if (type === 'detail') {
        if (detail.isWin) {
          totalEdge = detail.towerBet.split('-').length;
        } else {
          totalEdge = detail.towerBet.split('-').length - 1;
        }
      } else if (type === 'export') {
        if (!!parseInt(detail.isWin)) {
          totalEdge = detail.detail.split('-').length;
        } else {
          totalEdge = detail.detail.split('-').length - 1;
        }
      } else {
        return;
      }

      skull = type === 'detail' ? detail.isWin : !!parseInt(detail.isWin);
      return `${totalEdge ? `${totalEdge}x🥚` : ''}${!skull ? '💀' : ''}`;
    } else {
      return `------------------`;
    }
  };

  const getInfoBet = (orderDetail) => {
    if (!orderDetail) return <></>;

    if (orderDetail.game === "lottery") {
      return (
        <>
          {renderDetail(TEXT_DEF.text0166, transformInfo(orderDetail.detail))}
          {renderDetail(TEXT_DEF.text0167, orderDetail?.numberOfBet)}
          {renderDetail(TEXT_DEF.text0168, orderDetail?.multiple)}
          {renderDetail(TEXT_DEF.text0169, calculateAverageRevenuePerBet(orderDetail?.revenue, orderDetail?.numberOfBet, orderDetail?.multiple))}
        </>
      );
    }

    if (orderDetail.gameType === "video-poker") {
      return (
        <div>
          <div className="fw-medium fs-13">
            Thông tin đặt cược: 
          </div>
          <div className="mt-2 text-center">
            <div>Lượt 1: {orderDetail.play1st || '------'}</div>
            <div>Lượt 2: {orderDetail.play2nd || '------'}</div>
          </div>
        </div>
      )
    }

    if (orderDetail.gameType === "hilo") {
      const hilos = orderDetail?.hilo?.split(',');
      const multiHis = orderDetail?.multiHis?.split(',');

      return (
        <div>
          <div><label className="label">Thông tin đặt cược:</label> <span className="mr-2">▶️</span>
            {
              hilos ? 
              hilos.map((hilo, index) => {
                return (
                  <label className="mr-1"> {Number(hilo.trim()) == 1 ? (<ArrowUpOutlined />) : (Number(hilo.trim()) == -1 ? (<ArrowDownOutlined />) : '')} {multiHis[index]},</label>
                );
              }) :
              '---------'
            }
          </div>
        </div>
      )
    }

    if (orderDetail.gameType === "keno") {
      let inputString = orderDetail?.kenoHitBet;
      let numbersArray = inputString.split(',');
      numbersArray.sort((a, b) => parseInt(a) - parseInt(b));
      let sortedString = numbersArray.join(',');
      return (
        <div>
          <div><label className="label">Độ khó:</label> <span className="mr-2">{getKenoType(orderDetail.typeBet)}</span></div>
          <div><label className="label">Thông tin đặt cược:</label> 
            <span className="">
              {orderDetail.status == 'pending' ? ' ------' : sortedString.replace(/,/g, '-') }
            </span>
          </div>
        </div>
      )
    }

    if(orderDetail.gameType === "mines") {
      const minesBet = orderDetail?.minesBet?.split(',') || [];
      const bombs = orderDetail?.bombs?.split(',') || [];
      let countBombs = 0;
      let countDiammond = 0;
      
      for (let item = 0; item < minesBet.length; item++) {
          if (bombs.indexOf(minesBet[item]) !== -1) { // Sửa điều kiện kiểm tra
              countBombs += 1;
          }
      }
      countDiammond = minesBet.length - countBombs;

      return (
        <div>
          <div>
            <label className="label">Thông tin đặt cược:</label> <span className="mr-2"></span>
            <label className="mr-1">{orderDetail.status === 'pending' || orderDetail.status === 'canceled' ? '------' : `${countDiammond} 💎, ${countBombs}💣`} </label>
          </div>
          <div>
            <label className="label">Số mìn người chơi chọn:</label> <span className="mr-2"></span>
            <label className="mr-1">{orderDetail.mines}💣</label>
          </div>
        </div>
      );
    }

    if(orderDetail.gameType === "limbo") {
      const limboBet = orderDetail?.limboMultiBet;

      return (
        <div>
        <div><label className="label">Thông tin đặt cược:</label> <span className="mr-2"></span>
            <label className="mr-1">{
              orderDetail.status == 'pending' || orderDetail.status == 'canceled'  ? '------' : `>= ${limboBet}`} </label>
        </div>
      </div>
      )
    }

    if(orderDetail.gameType === "tower") {
      const towerBet = orderDetail?.towerBet;

      return (
        <div>
          <div>
            <label className="label">Độ khó:</label> <span className="mr-2"></span>
            <label className="mr-1">{transformTypeTower(orderDetail?.typeBet)} </label>
          </div>
          <div>
            <label className="label">Thông tin đặt cược:</label> <span className="mr-2"></span>
            <label className="mr-1">{detailBetTower(orderDetail, 'detail')} </label>
          </div>
        </div>
      );
    }

    if (orderDetail.gameType === 'diamond') {
      const baseColor = orderDetail?.diamonds;
      const splitNumbers = baseColor.split(',');
      const countDuplicate = {};
      const storageNumberOfRepeat = {};
      // let checkSameNumbers = 0;
      let total = 0;

      for (const item of splitNumbers) {
        countDuplicate[item] = (countDuplicate[item] || 0) + 1;
      }

      for (const [item, count] of Object.entries(countDuplicate)) {
        if (count > 1) {
          // checkSameNumbers += Math.floor(item / 2);
          storageNumberOfRepeat[item] = count;
          total += count;
        }
      }

      if (total > 0) {
        const values = Object.values(storageNumberOfRepeat);
        const first = values[0] ?? '';
        const second = values[1] ?? '';

        return (
          <>
            {second >= 3 ? (
              <div>
                <label className="label">Thông tin đặt cược:</label> <span className="mr-2"></span>
                <label className="mr-1">{orderDetail.status === 'pending' || orderDetail.status === 'canceled' ? '------' : `${second ? `${second}💎` : ''} ${first ? `- ${first}💎` : ''}`}</label>
              </div>
            ) : (
              <div>
                <label className="label">Thông tin đặt cược:</label> <span className="mr-2"></span>
                <label className="mr-1">{orderDetail.status === 'pending' || orderDetail.status === 'canceled' ? '------' : `${first ? `${first}💎` : ''} ${second ? `- ${second}💎` : ''}`}</label>
              </div>
            )}
          </>
        );
      } else {
        return (
          <div>
            <label className="label">Thông tin đặt cược:</label> <span className="mr-2"></span>
            <label className="mr-1">{orderDetail.status === 'pending' || orderDetail.status === 'canceled' ? '------' : `${total} 💎`}</label>
          </div>
        );
      }
    }

    if (orderDetail.gameType === 'coinflip') {
      const totalCoins = orderDetail?.flipcoinBet
        ?.split(',')
        ?.map((item) => item.trim())
        ?.filter((item) => item !== '');
      // const goldCoin = totalCoins.filter((number) => number === '1').length;
      // const silverCoin = totalCoins.filter((number) => number === '0').length;

      const goldCoinPositions = totalCoins?.map((item, index) => (item === '1' ? index : -1))?.filter((index) => index !== -1);

      const tmp = Array(totalCoins?.length)?.fill('⚪ ');
      goldCoinPositions?.forEach((pl) => (tmp[pl] = '🟡 '));

      const coinString = tmp?.join('');

      return (
        <div>
          <label className="label">Thông tin đặt cược:</label> <span className="mr-2"></span>
          {/* <label className="mr-1">{orderDetail.status === 'pending' || orderDetail.status === 'canceled' ? '------' : `${'🟡 '.repeat(goldCoin)} ${'⚪ '.repeat(silverCoin)}`}</label> */}
          <label className="mr-1">{orderDetail.status === 'pending' || orderDetail.status === 'canceled' ? '------' : `${coinString}`}</label>
        </div>
      );
    }

    if (orderDetail.gameType === 'wheel') {
      const risk = orderDetail.risk;
      const row = orderDetail.segments;
      const level = {
        easy: 'Thấp',
        normal: 'Trung Bình',
        hard: 'Cao',
      };

      return (
        <div>
          <label className="label">Cấp độ:</label> <span className="mr-2"></span>
          <label className="mr-1">{level[risk]}</label> <br />
          <label className="label">Hàng:</label> <span className="mr-2"></span>
          <label className="mr-1">{row}</label>
        </div>
      );
    }

    return (
      <></>
    );
  }

  const checkWin = (detail, arrayResult, numberToCheck, betTypeName = '', childBetTypeName = '', index)  => {
    
    if(arrayResult.length < 1)  {
      return numberToCheck;
    }
    if(arrayResult[0].indexOf(',')  > 0) {
      arrayResult = arrayResult[0].split(','); 
    }
    // giải đề 8
    if(childBetTypeName == 'Đề Giải 8' && index != 8) {
      return numberToCheck
    }
    // tổng 2 số đặc biệt
    if(childBetTypeName == '2 Số Đặc Biệt') {
      if(index != 0) {
        return numberToCheck;
      }
      if(['tong-0','tong-1', 'tong-2', 'tong-3', 'tong-4', 'tong-5', 'tong-6', 'tong-7', 'tong-8', 'tong-9',
      'tong-10', 'tong-11', 'tong-12', 'tong-13', 'tong-14', 'tong-15', 'tong-16', 'tong-17', 'tong-18'].includes(detail)) {
        if(index == 0) {
          const numberStr = numberToCheck.slice(-2)
          const sum = parseInt(numberStr.charAt(0)) + parseInt(numberStr.charAt(1));
          const parts = detail.split('-');
          return sum == parseInt(parts[1]) ? numberToCheck.slice(0, -2) + `<span style="color: red">${ numberToCheck.slice(-2)}</span>` : numberToCheck
        }
      }
      if(['tong-tai'].includes(detail)) {
        if(index == 0) {
          const numberStr = numberToCheck.slice(-2)
          const sum = parseInt(numberStr.charAt(0)) + parseInt(numberStr.charAt(1));
          return sum >= 10 ? numberToCheck.slice(0, -2) + `<span style="color: red">${ numberToCheck.slice(-2)}</span>` : numberToCheck
          
        }
      }
      if(['tong-xiu'].includes(detail)) {
        if(index == 0) {
          const numberStr = numberToCheck.slice(-2)
          const sum = parseInt(numberStr.charAt(0)) + parseInt(numberStr.charAt(1));
          return sum <= 8 ? numberToCheck.slice(0, -2) + `<span style="color: red">${ numberToCheck.slice(-2)}</span>` : numberToCheck
          
        }
      }
      if(['tai'].includes(detail)) {
        const numberStr = numberToCheck.slice(-2)
        if(parseInt(numberStr) >= 50) {
          return numberToCheck.slice(0, -2) + `<span style="color: red">${ numberToCheck.slice(-2)}</span>`;
        }
      }
      if(['xiu'].includes(detail)) {
        const numberStr = numberToCheck.slice(-2)
        if(parseInt(numberStr) <= 49) {
          return numberToCheck.slice(0, -2) + `<span style="color: red">${ numberToCheck.slice(-2)}</span>`;
        }
      }
      if(['chan'].includes(detail)) {
        if(numberToCheck % 2 == 0) {
          return numberToCheck.slice(0, -2) + `<span style="color: red">${ numberToCheck.slice(-2)}</span>`;

        }
      }
      if(['le'].includes(detail)) {
        if(numberToCheck % 2 == 0) {
          return numberToCheck.slice(0, -2) + `<span style="color: red">${ numberToCheck.slice(-2)}</span>`;

        }
      }
      
      if(['tong-le'].includes(detail)) {
        const lastTwoDigits = numberToCheck.toString().slice(-2); 

        const digit1 = parseInt(lastTwoDigits[0]);
        const digit2 = parseInt(lastTwoDigits[1]);

        let sum = digit1 + digit2;

        if(sum % 2 != 0) {
          return numberToCheck.slice(0, -2) + `<span style="color: red">${ numberToCheck.slice(-2)}</span>`;

        }
      }

      if(['tong-chan'].includes(detail)) {
        const lastTwoDigits = numberToCheck.toString().slice(-2); 

        const digit1 = parseInt(lastTwoDigits[0]);
        const digit2 = parseInt(lastTwoDigits[1]);

        let sum = digit1 + digit2;

        if(sum % 2 == 0) {
          return numberToCheck.slice(0, -2) + `<span style="color: red">${ numberToCheck.slice(-2)}</span>`;

        }
      }
    }

    if(childBetTypeName == '3 Càng Giải 7' && index != 7) {
      return numberToCheck;
    }

    if(childBetTypeName == '4 Càng Đặc Biệt' && index != 0) {
      return numberToCheck;
    }
    
    // giải đề đặc biệt
    if(childBetTypeName == 'Đề Đặc Biệt' && index !=0) {
      return numberToCheck
    }
  // console.log(detail, numberToCheck)
  if(childBetTypeName ==  'Đề Đầu Đặc Biệt') {
    if(index != 0) {
      return numberToCheck
    }

    const numberStr = numberToCheck.toString();
    const numCheck = numberStr.slice(0,2)
    if(arrayResult.includes(numCheck)) {
        return  `<span style="color: red">${numberStr.slice(0,2)}</span>` + numberStr.slice(2,5)
    }

  }
  if(childBetTypeName == 'Lô 2 Số Đầu') {
    const numberStr = numberToCheck.toString();
    const numCheck = numberStr.slice(0,2)
    if(arrayResult.includes(numCheck)) {
        return  `<span style="color: red">${numberStr.slice(0,2)}</span>` + numberStr.slice(2,5)
    }

    return numberToCheck;
  }

  if(childBetTypeName ==  'Đề Đầu Giải Nhất') {
    if(index != 1) {
      return numberToCheck
    }
    const numberStr = numberToCheck.toString();
    const numCheck = numberStr.slice(0,2)
    if(arrayResult.includes(numCheck)) {
        return  `<span style="color: red">${numberStr.slice(0,2)}</span>` + numberStr.slice(2,5)
    }

  }


    if(childBetTypeName == 'Đuôi' && index != 0 ){
      return numberToCheck
    }
    if(childBetTypeName == 'Đầu') {
      // const number = 437873;
      if(index == 0) {
          const numberStr = numberToCheck.toString();
          const secondLastDigit = numberStr.charAt(numberStr.length - 2);
          
          if(arrayResult.includes(secondLastDigit)) {
            const beforeSecondLastDigit = numberStr.slice(0, -2);
            const afterSecondLastDigit = numberStr.slice(-1);

            return beforeSecondLastDigit + `<span style="color: red">${secondLastDigit}</span>` + afterSecondLastDigit
          } 
        }
        return numberToCheck   
    }
    if(childBetTypeName == 'Đề Đầu Đuôi') {
      if(index != 8 && index != 0) {
        return numberToCheck;
      }
    }

    const number = arrayResult[0].length
    const lastTwoDigits = numberToCheck.slice(-number);
    let output = numberToCheck;
    if (arrayResult.includes(lastTwoDigits)) {
        output = numberToCheck.slice(0, -number) + `<span style="color: red">${lastTwoDigits}</span>`;
        return output
    }
    return numberToCheck
  }

  const showHtml = (awardDetails) => {
    return `${awardDetails.join(' - ')}`;
  };

  const getResult = (orderDetail) => {
    if(['pending'].includes(orderDetail.status)) {
      return (
        <></>
      );
    }
    if (!orderDetail) return (
      <></>
    );
    
    let betNumbers = []
    
    betNumbers = orderDetail?.winningNumbers?.length >  0 ? JSON.parse(orderDetail.winningNumbers[0]?.winningNumbers) : [];
    if(betNumbers.length > 0 && betNumbers[0].includes(',')) {
      betNumbers = betNumbers[0].split(',')
    }

    if (orderDetail.game === "lottery") {
      let orderDetailT = orderDetail;
      if(typeof orderDetail.awardDetail == 'string') {
        orderDetailT.awardDetail = JSON.parse(orderDetail.awardDetail)
      }
      let content = '';
      if (orderDetailT?.lotteryType === 'xstt' && orderDetailT?.type === 'xsmb') {
        const giaiDacBietHtml = showHtml([orderDetailT?.awardDetail?.[0]?.[0] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[0]?.[0], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 0) : '']);
        const giaiNhatHtml = showHtml([orderDetailT?.awardDetail?.[1]?.[0] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[1]?.[0], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 1) : '']);
        const giaiNhiHtml = showHtml([
          orderDetailT?.awardDetail?.[2]?.[0] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[2]?.[0], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 2) : '',
          orderDetailT?.awardDetail?.[2]?.[1] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[2]?.[1], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 2) : ''
        ]);
        const giai3Hhtml = showHtml([
          orderDetailT?.awardDetail?.[3]?.[0] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[3]?.[0], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 3) : '',
          orderDetailT?.awardDetail?.[3]?.[1] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[3]?.[1], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 3) : '',
          orderDetailT?.awardDetail?.[3]?.[2] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[3]?.[2], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 3) : '',
          orderDetailT?.awardDetail?.[3]?.[3] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[3]?.[3], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 3) : '',
          orderDetailT?.awardDetail?.[3]?.[4] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[3]?.[4], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 3) : '',
          orderDetailT?.awardDetail?.[3]?.[5] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[3]?.[5], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 3) : '',
        ]);
        const giai4Hhtml = showHtml([
          orderDetailT?.awardDetail?.[4]?.[0] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[4]?.[0], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 4) : '',
          orderDetailT?.awardDetail?.[4]?.[1] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[4]?.[1], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 4) : '',
          orderDetailT?.awardDetail?.[4]?.[2] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[4]?.[2], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 4) :  '',
          orderDetailT?.awardDetail?.[4]?.[3] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[4]?.[3], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 4) : '',
        ]);
        const giai5Hhtml = showHtml([
          orderDetailT?.awardDetail?.[5]?.[0] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[5]?.[0], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 5) : '',
          orderDetailT?.awardDetail?.[5]?.[1] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[5]?.[1], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 5) : '',
          orderDetailT?.awardDetail?.[5]?.[2] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[5]?.[2], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 5) : '',
          orderDetailT?.awardDetail?.[5]?.[3] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[5]?.[3], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 5) : '',
          orderDetailT?.awardDetail?.[5]?.[4] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[5]?.[4], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 5) : '',
          orderDetailT?.awardDetail?.[5]?.[5] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[5]?.[5], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 5) : '',
        ]);
        const giai6Hhtml = showHtml([
          orderDetailT?.awardDetail?.[6]?.[0] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[6]?.[0], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 6) : '',
          orderDetailT?.awardDetail?.[6]?.[1] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[6]?.[1], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 6) : '',
          orderDetailT?.awardDetail?.[6]?.[2] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[6]?.[2], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 6) : '',
        ]);
        const giai7Hhtml = showHtml([
          orderDetailT?.awardDetail?.[7]?.[0] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[7]?.[0], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 7) : '',
          orderDetailT?.awardDetail?.[7]?.[1] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[7]?.[1], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 7) : '',
          orderDetailT?.awardDetail?.[7]?.[2] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[7]?.[2], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 7) : '',
          orderDetailT?.awardDetail?.[7]?.[3] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[7]?.[3], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 7) : '',
        ]);

        content = `
          Giải đặc biệt: ${giaiDacBietHtml}
          Giải nhất: ${giaiNhatHtml}
          Giải nhì: ${giaiNhiHtml}
          Giải ba: ${giai3Hhtml}
          Giải tư: ${giai4Hhtml}
          Giải năm: ${giai5Hhtml}
          Giải sáu: ${giai6Hhtml}
          Giải bảy: ${giai7Hhtml}
        `;
      } else {
        content = `
          Giải đặc biệt: ${orderDetailT?.awardDetail?.[0]?.[0] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[0]?.[0], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 0) : ''}
          Giải nhất: ${orderDetailT?.awardDetail?.[1]?.[0] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[1]?.[0], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 1) : ''}
          Giải nhì: ${orderDetailT?.awardDetail?.[2]?.[0] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[2]?.[0], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 2) : ''}
          Giải ba: ${orderDetailT?.awardDetail?.[3]?.[0] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[3]?.[0], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 3) :  ''} - ${orderDetailT?.awardDetail?.[3]?.[1] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[3]?.[1], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 3) : ''}
          Giải tư: ${orderDetailT?.awardDetail?.[4]?.[0] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[4]?.[0], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 4) : ''} - ${orderDetailT?.awardDetail?.[4]?.[1] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[4]?.[1], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 4) : ''} - ${orderDetailT?.awardDetail?.[4]?.[2] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[4]?.[2], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 4) :  ''} - ${orderDetailT?.awardDetail?.[4]?.[3] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[4]?.[3], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 4) : ''}
          ${orderDetailT?.awardDetail?.[4]?.[4] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[4]?.[4], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 4) : ''} - ${orderDetailT?.awardDetail?.[4]?.[5] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[4]?.[5], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 4) : ''} - ${orderDetailT?.awardDetail?.[4]?.[6] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[4]?.[6], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 4) : ''}
          Giải năm: ${orderDetailT?.awardDetail?.[5]?.[0] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[5]?.[0], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 5) : ''}
          Giải sáu: ${orderDetailT?.awardDetail?.[6]?.[0] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[6]?.[0], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 6) : ''} - ${orderDetailT?.awardDetail?.[6]?.[1] ?checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[6]?.[1], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 6) : ''} - ${orderDetailT?.awardDetail?.[6]?.[2] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[6]?.[2], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 6) : ''}
          Giải bảy: ${orderDetailT?.awardDetail?.[7]?.[0] ? checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[7]?.[0], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 7) : ''}
          Giải tám: ${orderDetailT?.awardDetail?.[8]?.[0] ?checkWin(orderDetailT.detail, betNumbers, orderDetailT?.awardDetail?.[8]?.[0], orderDetailT.betTypeName, orderDetailT.childBetTypeName, 8) : ''}
        `;
      }

      return (
        <div>
          <div className="flex-space-between">
            {/* <div><label style={{fontWeight: 'bold'}} className="label" >Kỳ:</label> {orderDetail.turnIndex}</div> */}
            {/* <div><label className="label">
              {
                (orderDetail.status === "closed" ? 'Đã xổ' : 'Chưa xổ')
              }
            </label></div> */}
          </div>
          <div className="result" dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      )
    }
    
    if (orderDetail.gameType === "video-poker") {
      return (
        <div className="result">
          <div>
            {
              (!orderDetail.updatedBy && orderDetail.status != 'closed' ? ('Chưa xác nhận') : 
                (orderDetail.updatedBy === 'system' ? 
                  'Kết quả: Hoàn tất cược tự động' : 
                  'Kết quả: User hoàn tất cược'))
            }
          </div>
          <div className="text-danger fw-bold">{orderDetail.multi || 0}x</div>
          <div>{orderDetail.play2nd}</div>
        </div>
      );
    }

    if (orderDetail.gameType === "hilo") {
      let multiTemp = '0.00';
      if(orderDetail.multiHis) {
        const multiTemps = orderDetail.multiHis.split(", ");
        multiTemp = multiTemps[multiTemps.length -1];
      }
      return (
        <div className="result">
          <div>
            {
              (!orderDetail.updatedBy && orderDetail.status != 'closed' ? ('Chưa xác nhận') : 
                (orderDetail.updatedBy === 'system' ? 
                  'Kết quả: Hoàn tất cược tự động' : 
                  'Kết quả: User hoàn tất cược'))
            }
          </div>
          <div className="fw-bold text-danger">{multiTemp}x</div>
          <div>{orderDetail.cards}</div>
        </div>
      );
    }

    if (orderDetail.gameType === "mines") {
      return (
        <div className="result">
          <div>
            {
              (!orderDetail.updatedBy && orderDetail.status != 'closed' ? ('Chưa xác nhận') : 
                (orderDetail.updatedBy === 'system' ? 
                'Kết quả: Hoàn tất cược tự động' : 
                'Kết quả: User hoàn tất cược'))
            }
          </div>
          <div className="fw-bold text-danger">{orderDetail.multi || 0}x</div>
        </div>
      );
    }
    if (orderDetail.gameType === "keno") {

      const kenoHitBet = orderDetail?.kenoHitBet?.split(',');
      let kenoHitResult = orderDetail?.kenoHitResult?.split(',');
      kenoHitResult = kenoHitResult.sort((a, b) => parseInt(a) - parseInt(b));
      const isHitBet = (number) => kenoHitBet.includes(number);
      return (
        <div className="result">
          <div>
            {
              (!orderDetail.updatedBy && orderDetail.status != 'closed' ? ('Chưa xác nhận') : 
                (orderDetail.updatedBy === 'system' ? 
                'Kết quả: Hoàn tất cược tự động' : 
                'Kết quả: User hoàn tất cược'))
            }
          </div>
          <div className="fw-bold text-danger">{orderDetail.multi || 0}x</div>
          <div className="numbers">
              {kenoHitResult.map((number, index) => (
                  <span key={index}>
                      <span
                          style={{
                              color: isHitBet(number) ? 'red' : 'black',
                          }}
                      >
                          {number}
                      </span>
                      {index < kenoHitResult.length - 1 && ' - '}
                  </span>
              ))}
            </div>
        </div>
      );
    }

    if (orderDetail.gameType === "limbo") {
      let multiTemp = '0.00';
      if(orderDetail.isWin == 1) {
        multiTemp = orderDetail.limboMultiBet;
      }
      return (
        <div className="result">
          <div>
            {
              (!orderDetail.updatedBy && orderDetail.status != 'closed' ? ('Chưa xác nhận') : 
                (orderDetail.updatedBy === 'system' ? 
                  'Kết quả: Hoàn tất cược tự động' : 
                  'Kết quả: User hoàn tất cược'))
            }
          </div>
          <div className="fw-bold text-danger">{multiTemp}x</div>
          <div>{orderDetail.limboMultiResult}</div>
        </div>
      );
    }

    if (orderDetail.gameType === "tower") {

      const towerBet = orderDetail?.towerBet?.split(',');
      let towerResult = orderDetail?.towerResult?.split(',');
      towerResult = towerResult.sort((a, b) => parseInt(a) - parseInt(b));
      const isHitBet = (number) => towerBet.includes(number);
      return (
        <div className="result">
          <div>
            {
              (!orderDetail.updatedBy && orderDetail.status != 'closed' ? ('Chưa xác nhận') : 
                (orderDetail.updatedBy === 'system' ? 
                'Kết quả: Hoàn tất cược tự động' : 
                'Kết quả: User hoàn tất cược'))
            }
          </div>
          <div className="fw-bold text-danger">{orderDetail.multi || 0}x</div>
          {/* <div className="numbers">
              {towerResult.map((number, index) => (
                  <span key={index}>
                      <span
                          style={{
                              color: isHitBet(number) ? 'red' : 'black',
                          }}
                      >
                          {number}
                      </span>
                      {index < towerResult.length - 1 && ' - '}
                  </span>
              ))}
            </div> */}
        </div>
      );
    }

    if (orderDetail.gameType === 'diamond') {
      return (
        <div className="result">
          <div>{!orderDetail.updatedBy && orderDetail.status != 'closed' ? 'Chưa xác nhận' : orderDetail.updatedBy === 'system' ? 'Kết quả: Hoàn tất cược tự động' : 'Kết quả: User hoàn tất cược'}</div>
          <div className="fw-bold text-danger">{orderDetail.multi || 0}x</div>
        </div>
      );
    }

    if (orderDetail.gameType === 'coinflip') {
      return (
        <div className="result">
          <div>{!orderDetail.updatedBy && orderDetail.status != 'closed' ? 'Chưa xác nhận' : orderDetail.updatedBy === 'system' ? 'Kết quả: Hoàn tất cược tự động' : 'Kết quả: User hoàn tất cược'}</div>
          <div className="fw-bold text-danger">{orderDetail.multi || 0}x</div>
        </div>
      );
    }

    if (orderDetail.gameType === 'wheel') {
      return (
        <div className="result">
          <div>{!orderDetail.updatedBy && orderDetail.status != 'closed' ? 'Chưa xác nhận' : orderDetail.updatedBy === 'system' ? 'Kết quả: Hoàn tất cược tự động' : 'Kết quả: User hoàn tất cược'}</div>
          <div className="fw-bold text-danger">{orderDetail.multi || 0}x</div>
        </div>
      );
    }

    return (
      <></>
    );
  }

  const getMoneyReturned = (orderDetail) => {
    if (!orderDetail) return (
      <></>
    );

    if (orderDetail.game === "lottery") {
      return (
        dollarUS.format(Number(orderDetail.paymentWin) + Number(orderDetail.revenue))
      )
    }

    return (
      <>{dollarUS.format((Number(orderDetail.paymentWin)))}</>
    )
  }

  const renderSearch = () => {
    return (
      <>
        <div className="col-12">
          <Form
            form={form}
            layout="inline"
            autoComplete="off"
            onFinish={() => onSearch()}
          >
            <div
              className="col-12"
              style={{ marginBottom: 15, display: "flex" }}
            >
              {
                hasRight([RIGHTS.Super]) ? (
                  <div className="col-4">
                    <Form.Item name="bookmarker" label={TEXT_DEF.text0011} rules={[{ required: true, message: 'Vui lòng nhập thông tin!' }]}>
                      <Select
                        showSearch
                        placeholder="Chọn nhà cái"
                        // optionFilterProp="children"
                        onChange={(val) => setBookmarkerSelected(val)}
                        filterOption={filterOptionBm}
                        options={bookmarkers}
                        allowClear
                      />
                    </Form.Item>
                  </div>
                ) : null
              }
              <div className="col-4">
                <Form.Item name="orderId" label={`Mã đơn cược`}>
                  <Input style={{ width: '100%' }} />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item name="username" label={`Tên HV mã hóa`}>
                  <Input style={{ width: '100%' }} />
                </Form.Item>
              </div>
            </div>
            <div
              className="col-12"
              style={{ marginBottom: 15, display: "flex" }}
            >
              <div className="col-4">
                <Form.Item name="gameCategory" label={`Thể loại game`} rules={[{ required: true, message: 'Vui lòng nhập thông tin!' }]}>
                  <Select
                    showSearch
                    optionFilterProp="children"
                    onChange={(val) => setGameCategory(val)}
                    filterOption={filterOptionBm}
                    options={opGameCategory}
                    allowClear
                  />
                </Form.Item>
              </div>
              {gameCategory &&
                <div className="col-8">
                  <Form.Item name="gameType" label={`Game`} >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      onChange={(val) => setGame(val)}
                      filterOption={filterOptionBm}
                      options={gameTypes}
                      allowClear
                    />
                  </Form.Item>
                </div>}
            </div>
            <div
              className="col-12"
              style={{ marginBottom: 15, display: "flex" }}
            >
              <div className="col-4">
                <Form.Item name="status" label={TEXT_DEF.text0147}>
                  <Select
                    showSearch
                    placeholder="Chọn trạng thái"
                    optionFilterProp="children"
                    onChange={(val) => setStatus(val)}
                    filterOption={''}
                    options={gameStatus}
                    allowClear
                  />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item name="dateFrom" label={TEXT_DEF.text0024} rules={[{validator: () => { return validateDateRange(dateFrom, dateTo)}}]} >
                  <DatePicker
                    picker={typeSl}
                    placeholder=""
                    onChange={(e) => onChangeDate(e, "from")}
                    format={"DD-MM-YYYY HH:mm:ss"}
                    showTime={{ format: "HH:mm:ss" }}
                    style={{ width: "100%" }}
                    disabledTime={disabledTimeFrom(dateTo)}
                    disabledDate={disabledDateFrom}
                    // inputReadOnly={true}
                  />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item name="dateTo" label={TEXT_DEF.text0025} >
                  <DatePicker
                    picker={typeSl}
                    placeholder=""
                    onChange={(e) => onChangeDate(e, "to")}
                    format={"DD-MM-YYYY HH:mm:ss"}
                    showTime={{ defaultValue: moment('23:59:59', 'HH:mm:ss') }}
                    style={{ width: "100%" }}
                    disabledDate={disabledDateTo}
                    disabledTime={disabledTimeTo(dateFrom)}
                    // inputReadOnly={true}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="payment-search">
              <Button htmlType="submit" type="primary">
                {TEXT_DEF.text0026}
              </Button>
              {
                hasRight([RIGHTS.ExportExcelReportOrders]) ? (
                  <Button onClick={onExportExl} style={{ marginLeft: 20 }} htmlType="submit" type="primary">
                    {TEXT_DEF.text0027}
                  </Button>
                ) : null
              }
            </div>
          </Form>
        </div>

        <Modal
          title="Chi tiết đơn cược"
          open={open}
          onCancel={() => setOpen(false)}
          centered={true}
          footer={
            <Button key="submit" type="primary" onClick={hideModal}>
              Đóng
            </Button>
          }
        >
          <div className="order-detail">
            <div className="content">
              <div>
                <label className="label">Mã đơn cược: </label> {orderDetail.code}</div>
              <div>
                <label className="label">Trạng thái: </label> {getStatus(orderDetail)}
              </div>
              <div>
                <label className="label">Thời gian đặt cược:&nbsp;</label>
                {
                  (orderDetail.game === "casino") ? (
                    <label> {(new Date((orderDetail.openTime))).toLocaleDateString()} - {(new Date((orderDetail.openTime))).toLocaleTimeString()}</label>
                  ) : (
                    // <label> {(new Date((orderDetail.openTime))).toLocaleDateString()} - {(new Date((orderDetail.openTime))).toLocaleTimeString()}</label>
                    <label>
                    {new Date(orderDetail.createdAt)
                      .toLocaleString("vi-VN", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                        hour12: false,
                      })
                      .replace(",", "")}
                  </label>
                  )
                }
              </div>
              <div>
                <label className="label">Thời gian kết thúc:&nbsp;</label>
                {
                  (orderDetail.game === "casino") ? (
                    <label> { orderDetail.status == 'pending' ? '--------' : (new Date((orderDetail.closeTime))).toLocaleDateString()} - { orderDetail.status == 'pending' ? '--------' :(new Date((orderDetail.closeTime))).toLocaleTimeString()}</label>
                  ) : (
                    // <label> {(new Date((Number(orderDetail.closeTime)))).toLocaleDateString()} - {(new Date((Number(orderDetail.closeTime)))).toLocaleTimeString()}</label>
                    // <label> {moment((new Date((Number(orderDetail.closeTime))))).format('MM/DD/yyy - hh:mm:ss A')}</label>
                    <label>
                    {orderDetail.status == 'pending' ? '--------' : new Date(orderDetail.updatedAt)
                      .toLocaleString("vi-VN", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                        hour12: false,
                      })
                      .replace(",", "")}
                  </label>

                  )
                }
              </div>
              <div className="flex-space-between">-----------------</div>
              <div>
                <label className="label">Tiền đặt cược: {dollarUS.format(Number(orderDetail.revenue))}</label>
              </div>
              <div>
                <label className="label">Tiền nhận lại: { (orderDetail.status == 'pending' || orderDetail.status == 'warning') ? '--------' : getMoneyReturned(orderDetail)}</label>
              </div>
              <div className="flex-space-between">-----------------</div>
              <div className="flex-space-between">
                <div><label className="label">Thể loại: </label> {orderDetail.game === 'lottery' ? 'Xổ số' : 'Game Việt'}</div>
                {/* {
                  (orderDetail.game !== "casino") ? (
                    <div><label className="label">Kỳ: </label> {orderDetail.turnIndex}</div>
                  ) : null
                } */}
              </div>
              <div><label className="label">Game: </label> { orderDetail.game == 'casino' ?  transformOrginalType(orderDetail.gameType) : orderDetail.gameType}</div>
              {
                (orderDetail.game !== "casino") ? (
                  <div><label className="label">Kiểu chơi: </label> {orderDetail.betTypeName} - {orderDetail.childBetTypeName}</div>
                ) : null
              }

             

              {getInfoBet(orderDetail)}
              <div className="flex-space-between">-----------------</div>
              <>
                  <>
                  {orderDetail.game !== "casino" && ['pending', 'canceled', 'closed'].includes(orderDetail.status) ? 
                    <div ><span style={{fontWeight: 500, fontSize: '13px'}}>Kỳ:</span> <span >{orderDetail.turnIndex}</span></div>: 
                  <></>}
                  {orderDetail.game !== "casino" && ['pending'].includes(orderDetail.status) ? 
                    <div><span style={{fontWeight: 500, fontSize: '13px'}}>Kết quả:</span> <span >{['pending'].includes(orderDetail.status) ?  'Chưa xổ' : ''}</span></div>
                  : <></>}
                  {orderDetail.game !== "casino" && ['closed'].includes(orderDetail.status) ? 
                    <div><span style={{fontWeight: 500, fontSize: '13px'}}>Kết quả:</span> <span > Đã xổ</span></div>
                  : <></>}
                  {orderDetail.status !== 'canceled' ? (
                    <>
                  {getResult(orderDetail)}
                  </> ) : null}
                  </>
              </>
            </div>
          </div>
        </Modal>
      </>
    );
  };

  const onChangePage = (page, size) => {
    setPage(page);
    setPageSize(size);
  };

  const showTotal = (total) => `Tổng cộng ${total} dữ liệu`;

  const renderTableData = () => {
    return (
      <Spin spinning={loading} delay={500} size="large">
        <div
          style={{
            display: "flex",
            marginTop: 5,
            marginRight: 20,
          }}
        >
          <div className="text-lg font-bold mr-5">
            <label>Tổng tiền cược: </label> <span>{dollarUS.format(finalInfo.allBet)}</span>
          </div>
          <div className="text-lg font-bold mr-5">
            <label>Tổng tiền khách thắng: </label> <span>{dollarUS.format(finalInfo.allAmountUserWin)}</span>
          </div>
          <div className="text-lg font-bold mr-5">
            <label>Tổng lợi nhuận: </label> <span>{dollarUS.format(finalInfo.allProfit)}</span>
          </div>
        </div>
        <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
          <Table
            key="userList"
            locale={EMPTY_DATA}
            columns={colums}
            dataSource={ordersInfo}
            pagination={false}
            bordered
            style={{ width: "100%" }}
            scroll={{
              x: 1200,
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            marginTop: 15,
            justifyContent: "flex-end",
          }}
        >
          <Pagination 
            current={page} 
            total={totalRecord} 
            onChange={onChangePage}
            showTotal={showTotal} 
            showSizeChanger
            onShowSizeChange={onChangePage}
            pageSizeOptions={['10', '50', '100', '200', '500', '1000']}
          />
        </div>
      </Spin>
    );
  }

  return (
    <div className="col-12">
      {renderSearch()}
      {renderTableData()}
      {isShowModalChart && (
        <ModalChart
          isShow={isShowModalChart}
          dataChart={dataChartModal}
          title={titleChartModal}
          close={() => setIsShowModalChart(false)}
        />
      )}
    </div>
  );
};

export default ReportByOrder;
